/* 登录 */
<template>

  <div class="register wrapper" style="width:600px; padding-top: 50px;">
    <el-form
      ref="ruleFormRef"
      :model="loginInfo"
      :rules="rules"
      label-width="240px"
      class="pt-30"
      size="lager"
      status-icon
    >
      <el-form-item label="登录账号：" prop="uid">
        <el-input v-model="loginInfo.uid" placeholder="登录账号" autocomplete="off"/>
      </el-form-item>
      <el-form-item label="密      码：" prop="pwd">
        <el-input v-model="loginInfo.pwd" type="password" placeholder="密码（格式:字母+数字组合）" autocomplete="off" />
      </el-form-item>
      <el-form-item label="验证码：" prop="captcha">
        <div class="center-float-left">
          <el-input class="flex-1 mr-10" v-model="loginInfo.captcha"></el-input>
          <div class="login-captcha"><img v-show="captchaImg" :src="captchaImg" width="110" height="40" @click="getCaptchaImg()" /></div>
        </div>
      </el-form-item>
      <el-form-item class="text-center">
        <el-button type="primary" @click="handleLogin" size="large" :loading="loading" style="width: 100%;">登录</el-button>
        <router-link to="/register/pectoralgia" class="pl-30">暂无账号，点击注册</router-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getCaptcha, login } from '@/api/index'

export default{
  name: "LoginView",
  data() {
    
    return {
      loading: false,
      captchaImg: '',

      loginInfo: {
        uid: '',
        pwd: '',
        captcha: '',
        serialNo: ''
      },
      rules: {
        uid: [{ required: true, message: '请填写用户登录名', trigger: 'blur' }],
        pwd: [{ required: true, message: '请填写用户登录密码', trigger: 'blur' }],
        captcha: [{ required: true, message: '请填写验证码', trigger: 'blur' }],
      },
      userInfo: null,
    }
  },
  mounted() {
    this.getCaptchaImg()
    let userInfo = JSON.parse(localStorage.getItem('cpis-web_userInfo'))
    this.userInfo = userInfo && userInfo.id ? userInfo: null
    if (this.userInfo) {
      this.$message.success('已登录！')
      let query = this.$route.query
      if (query.redirect) {
        const path = query.redirect
        this.$router.replace({ path, query })
      } else {
        this.$router.replace('/')
      }
    }
  },
  methods: {
    async getCaptchaImg() {
      const res = await getCaptcha()
      this.captchaImg = res.biz.image
      this.loginInfo.serialNo = res.biz.key
    },
    handleLogin() {
      this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          console.log('submit!')
          this.reqSubmit()
        } else {
          console.log('error submit!', fields)
        }
      })
    },
    async reqSubmit() {
      try {
        const self=this;
        this.loading = true
        this.$axios.post('/api/customer/login', this.loginInfo)
            .then((res) => {
              if(res.msg.flag == 0){
                if (null === res.biz || res.biz.empty) {
                  self.$message.error(res.msg.message)
                } else {
                  localStorage.setItem('cpis-web_userInfo', JSON.stringify(res.biz))
                  // this.$router.go(0)
                  this.$router.push('/index');
                  window.location.reload()
                }
              }else{
                self.getCaptchaImg()
                self.$message.error(res.msg.message)
              }
            }).catch(() => {
        });
        // const res = await login(this.loginInfo)

      } catch (error) {
        console.error(error)
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
.login-captcha{
  border-radius: 5px;
  overflow: hidden;
  height: 40px;
}
</style>

